import React, { useEffect, useRef, useState } from 'react';
import { ASAP, SCHEDULE } from '../constants/deliveryType';
import appConfig from '../appConfig';
import { useDeliveryDetails } from '../hooks/app';

const NoProductAvailable = ({
  asapProductsCount = 0,
  scheduledProductsCount = 0,
  currentTab,
  setTab,
  applyFilters,
  asapEnabled,
  scheduleEnabled,
  unified_menu,
  className = '',
  setTrack
}) => {

  const isAnyTabDisabled = !asapEnabled || !scheduleEnabled;
  const changeTab = () => {
    currentTab === ASAP ? setTab(SCHEDULE) : setTab(ASAP);
  };

  useEffect(() => {
    setTrack ? setTrack([], null, true) : null;
  }, [])

  return (
    <div className={`no-product-available ${className}`}>

      <div style={!appConfig.isGrassdoor ? { filter: "grayscale(100%)" } : {}}>
        <FilterEmptyState
          isAnyTabDisabled={isAnyTabDisabled}
          unified_menu={unified_menu}
          currentTab={currentTab}
          scheduledProductsCount={scheduledProductsCount}
          asapProductsCount={asapProductsCount}
        />
      </div>

      <div>
        {unified_menu === 1
          ? `Please clear your filters to find what you're looking for.`
          : isAnyTabDisabled
            ? "Please clear your filters to find what you're looking for."
            : (scheduledProductsCount === 0 && asapProductsCount === 0)
              ? <div>Please reduce / reset filter selection to find what you're looking for.</div>
              : `Please search our expanded ${currentTab === ASAP ? "Scheduled" : "ASAP"} menu selection to find what you're looking for`}
      </div>
      {isAnyTabDisabled || (scheduledProductsCount === 0 && asapProductsCount === 0)
        ? null
        : (
          <button className="btn btn-primary" onClick={() => changeTab()}>
            Show {scheduledProductsCount === 0 ? "ASAP" : "Scheduled"} Menu
          </button>
        )
      }
    </div>
  );
};

const FilterEmptyState = ({ isAnyTabDisabled, unified_menu, currentTab, scheduledProductsCount = 0, asapProductsCount = 0 }) => {

  const [hasTimeRemaining, setHasTimeRemaining] = useState(false);
  const [remainingTime, setRemainingTime] = useState(-1);
  const interval = useRef(null);

  const currentTime = new Date().toLocaleTimeString('en-US', {
    timeZone: 'America/Los_Angeles',
    hourCycle: 'h23'
  });

  const {
    data: { asapStartTime }
  } = useDeliveryDetails(false, true);

  const clearIntervals = () => {
    clearInterval(interval.current);
  };

  useEffect(() => {
    if (asapStartTime) {
      const currentTimeArray = currentTime.split(':');
      const asapTimeArray = asapStartTime.split(':');

      if (currentTimeArray.length === 3 && asapTimeArray.length === 3) {
        let hourDifference = 0, minutesDifference = 0, secondsDifference = 0;

        if (parseInt(currentTimeArray[0]) > parseInt(asapTimeArray[0])) {
          hourDifference = 24 - parseInt(currentTimeArray[0]) + parseInt(asapTimeArray[0]);
        } else {
          hourDifference = parseInt(asapTimeArray[0]) - parseInt(currentTimeArray[0]);
        }

        if (parseInt(currentTimeArray[1]) > parseInt(asapTimeArray[1])) {
          minutesDifference = 60 - parseInt(currentTimeArray[1]) + parseInt(asapTimeArray[1]);
          hourDifference -= 1;
        } else {
          minutesDifference = parseInt(asapTimeArray[1]) - parseInt(currentTimeArray[1]);
        }

        if (parseInt(currentTimeArray[2]) > parseInt(asapTimeArray[2])) {
          secondsDifference = 60 - parseInt(currentTimeArray[2]) + parseInt(asapTimeArray[2]);
          minutesDifference -= 1;
        } else {
          secondsDifference = parseInt(asapTimeArray[2]) - parseInt(currentTimeArray[2]);
        }

        const totalSeconds = hourDifference * 3600 + minutesDifference * 60 + secondsDifference;

        // Set hasTimeRemaining if totalSeconds > 1, otherwise set to false
        setHasTimeRemaining(totalSeconds > 1);
        setRemainingTime(totalSeconds);
      }
    } else {
      setHasTimeRemaining(false);
    }
  }, [asapStartTime]);

  useEffect(() => {
    if (remainingTime > 0) {
      interval.current = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setHasTimeRemaining(false);
    }

    return () => clearIntervals();
  }, [remainingTime]);
  if (isAnyTabDisabled || hasTimeRemaining || unified_menu === 1) {
    return (
      <>
        <img src="/static/images/no-products.png" width="300px" alt="" />
        <h4>No products here!</h4>
      </>
    )
  }

  return (
    (currentTab === ASAP && (scheduledProductsCount && scheduledProductsCount !== 0)) ? (
      <>
        <img src="/static/images/no-products-asap.svg" width="300px" alt="" />
        <h4>{scheduledProductsCount} {scheduledProductsCount === 1 ? "Product" : "Products"} in Scheduled Menu</h4>
      </>
    ) : (currentTab === SCHEDULE && (asapProductsCount && asapProductsCount !== 0)) ? (
      <>
        <img src="/static/images/no-products-scheduled.svg" width="300px" alt="" />
        <h4>{asapProductsCount} {asapProductsCount === 1 ? "Product" : "Products"} in ASAP Menu</h4>
      </>
    ) : (
      <>
        <img src="/static/images/no-products.png" width="300px" alt="" />
        <h4>No products here!</h4>
      </>
    )
  )
}

export default NoProductAvailable;